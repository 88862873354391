"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsAbrechnungTerminBehandlerColumnsDefs = void 0;
const editSettingsStore_1 = require("@/state/settings/editSettingsStore");
function settingsAbrechnungTerminBehandlerColumnsDefs() {
    return [
        {
            headerName: 'Behandler',
            valueGetter: params => params.data,
            comparator: (a, b) => { var _a; return (_a = a.name) === null || _a === void 0 ? void 0 : _a.localeCompare(b.name); },
            cellRenderer: 'BehandlerCell',
            sort: 'asc',
            sortable: true,
            width: 200,
            flex: 1,
        },
        {
            headerName: '',
            field: 'actions',
            cellRenderer: 'DeleteButtonCell',
            width: 40,
            cellStyle: { textAlign: 'center', 'justify-content': 'center' },
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.deleteIgnorierteTerminbehandler(field.row.extid);
                    },
                },
            },
        },
        {
            headerName: '',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 40,
        },
    ];
}
exports.settingsAbrechnungTerminBehandlerColumnsDefs = settingsAbrechnungTerminBehandlerColumnsDefs;
