/* unplugin-vue-components disabled */import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-5"},[_c(VCardTitle,[_c('h3',[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,[_c(VAlert,{staticClass:"mb-5",attrs:{"prominent":"","outlined":"","border":"left","text":"","type":"info"}},[_vm._v(_vm._s(_vm.description))]),_c(VCard,{staticClass:"px-2 py-2",attrs:{"color":"grey lighten-4","flat":"","outlined":""}},[_c('div',{staticClass:"d-flex align-center px-0 py-1"},[_c(VAutocomplete,{attrs:{"items":_vm.itemsAvailable,"small-chips":"","dense":"","solo":"","flat":"","clearable":"","deletable-chips":"","multiple":"","hide-details":"","label":"Verfügbare Terminarten","item-text":"bezeichnung","item-value":"extid"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pr-3 nummer"},[_vm._v(_vm._s(data.item.bezeichnung))])])]}}]),model:{value:(_vm.itemsAdded),callback:function ($$v) {_vm.itemsAdded=$$v},expression:"itemsAdded"}}),_c(VBtn,{staticClass:"ml-2",attrs:{"color":"primary","disabled":_vm.itemsAdded.length === 0},on:{"click":function($event){return _vm.addItems()}}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("fa-plus")]),_c('span',[_vm._v("Hinzufügen")])],1)],1),_c('div',{staticClass:"mt-1"},[_c('ag-grid-vue',{staticClass:"ag-theme-balham",attrs:{"column-defs":_vm.columns,"row-data":_vm.items,"locale-text":_vm.localeText,"grid-options":_vm.gridOptions,"row-class":_vm.rowClass,"get-row-class":_vm.getRowClass},on:{"grid-ready":_vm.onGridReady,"cellClicked":_vm.onCellClicked,"selection-changed":_vm.onSelectionChanged}}),_c('div',{staticClass:"pt-2 d-flex justify-end"},[_c(VBtn,{attrs:{"color":"red","text":"","disabled":!_vm.showDeleteAllSelected},on:{"click":_vm.deleteItems}},[_c('font-awesome-icon',{staticClass:"mx-1",attrs:{"size":"sm","icon":["fas", "trash"]}}),_c('div',{staticClass:"pl-2"},[_vm._v("Ausgewählte Löschen")])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }