"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsAbrechnungTerminArtenColumnsDefs = void 0;
const editSettingsStore_1 = require("@/state/settings/editSettingsStore");
function settingsAbrechnungTerminArtenColumnsDefs() {
    return [
        {
            headerName: 'Terminart',
            field: 'bezeichnung',
            valueFormatter: params => { var _a, _b; return `${(_a = params.data) === null || _a === void 0 ? void 0 : _a.bezeichnung} (${(_b = params.data) === null || _b === void 0 ? void 0 : _b.extid})`; },
            sortable: true,
            sort: 'asc',
            flex: 1,
        },
        {
            headerName: '',
            field: 'actions',
            cellRenderer: 'DeleteButtonCell',
            width: 40,
            cellStyle: { textAlign: 'center', 'justify-content': 'center' },
            cellRendererParams: {
                context: {
                    clicked(field) {
                        editSettingsStore_1.editSettingsStore.commit.deleteIgnorierteTerminart(field.row.extid);
                    },
                },
            },
        },
        {
            headerName: '',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 40,
        },
    ];
}
exports.settingsAbrechnungTerminArtenColumnsDefs = settingsAbrechnungTerminArtenColumnsDefs;
