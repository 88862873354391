/* unplugin-vue-components disabled */import __unplugin_components_2 from '/builds/rose-metrics/rose-metrics/vui/src/components/tables/settings/Abrechnung/AbrechnungTableTerminBehandler.vue';
import __unplugin_components_1 from '/builds/rose-metrics/rose-metrics/vui/src/components/tables/settings/Abrechnung/AbrechnungTableTerminArten.vue';
import __unplugin_components_0 from '/builds/rose-metrics/rose-metrics/vui/src/components/tables/settings/Abrechnung/AbrechnungTable.vue';
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_c('h3',[_vm._v("Regel-Einstellungen")])]),_c(VCardText,[_c(VAlert,{staticClass:"mb-5",attrs:{"prominent":"","outlined":"","border":"left","text":"","type":"info"}},[_vm._v("Hier kannst Du die Einstellungen zu einzelnen Regeln ändern")]),_vm._l((_vm.regelGroupedByCategory),function(rules,name){return _c(VRow,{key:name},[_c(VCol,{attrs:{"cols":"12"}},[_c(__unplugin_components_0,{staticClass:"mb-5",attrs:{"leistung-key":name,"title":name,"items":rules}})],1)],1)})],2)],1),_c(__unplugin_components_1,{attrs:{"title":"Ignorierte Terminarten","description":"Für folgende Terminarten werden keine Abrechnungsvorschläge erstellt. Dies kann sinnvoll sein, wenn für bestimmte Terminarten grundsätzlich keine Leistungen erfasst werden, z.B. Techniker Termine, private/praxisinterne Termine etc. und hierfür keine allgemeinen Termine genutzt werden","items":_vm.ignoriereTerminArten,"items-available":_vm.availableTerminArten,"columns":_vm.columnArtenDefs},on:{"addItems":_vm.addTerminArten,"deleteItems":_vm.removeTerminArten}}),_c(__unplugin_components_2,{attrs:{"title":"Ignorierte Terminbehandler","description":"Für folgende Termin-Behandler werden keine Abrechnungsvorschläge erstellt. Dies kann sinnvoll sein, wenn Terminbehandler für die Planung von Terminen benutzt werden, für die aber keine Leistungen erfasst werden, z.B. Techniker Termine, private/praxisinterne Termine etc","items":_vm.ignoriereTerminBehandler,"items-available":_vm.availableTerminBehandler,"columns":_vm.columnBehandlerDefs},on:{"addItems":_vm.addTerminBehandler,"deleteItems":_vm.deleteTerminBehandler}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }