"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsAbrechnungColumnsDefs = void 0;
function settingsAbrechnungColumnsDefs() {
    return [
        {
            headerName: 'Regel',
            field: 'kurzName',
            sortable: true,
            sort: 'asc',
            width: 350,
        },
        {
            headerName: 'Hilfe',
            field: 'beschreibung',
            width: 80,
            // cellClass: 'ag-cell-stichworte',
            valueFormatter: params => { var _a, _b; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.beschreibung) || ((_b = params.data) === null || _b === void 0 ? void 0 : _b.beschreibungVorschlag) || ''; },
            cellRenderer: 'HelpTooltipCell',
        },
        {
            headerName: 'Art',
            field: 'abrechnungsart',
            valueFormatter: params => { var _a; return (((_a = params.data) === null || _a === void 0 ? void 0 : _a.abrechnungsart) === 0 ? 'GOZ' : 'BEMA'); },
            width: 80,
            sortable: true,
        },
        {
            headerName: 'Vorschlagsart',
            field: 'vorschlagsart',
            valueFormatter: params => { var _a; return (((_a = params.data) === null || _a === void 0 ? void 0 : _a.typ) === 'ABRECHNUNG' ? 'Abrechnung' : 'Behandlung'); },
            resizable: true,
            width: 110,
        },
        {
            headerName: 'Aktiv',
            width: 80,
            cellStyle: { textAlign: 'center', 'justify-content': 'center' },
            cellRenderer: 'AARegelActiveCell',
        },
        {
            headerName: 'Überweiser',
            headerTooltip: 'Regel bei Überweisern ignorieren',
            width: 120,
            cellStyle: { textAlign: 'center', 'justify-content': 'center' },
            cellRenderer: 'AAIgnoreUeberweiserCell',
        },
        {
            headerName: 'Regel bei diesen Stichworten ignorieren',
            field: 'typ',
            flex: 1,
            cellClass: 'ag-cell-stichworte',
            cellRenderer: 'StichworteCell',
        },
        {
            headerName: 'Weitere Einstellungen',
            field: 'weitere',
            width: 320,
            cellRenderer: 'EinstellungenCell',
        },
    ];
}
exports.settingsAbrechnungColumnsDefs = settingsAbrechnungColumnsDefs;
