"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var common_ui_1 = require("@rose/common-ui");

var abrechnung_1 = require("@/components/columns/settings/abrechnung");

var types_1 = require("@rose/types");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    title: {
      type: String,
      required: true
    },
    leistungKey: {
      type: String,
      required: true
    },
    items: {
      type: [],
      required: true
    }
  },
  data: function data() {
    return {
      localeText: common_ui_1.german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: null,
      dialog: false
    };
  },
  created: function created() {
    this.columnDefs = (0, abrechnung_1.settingsAbrechnungColumnsDefs)();
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        autoHeight: true
      },
      suppressCellFocus: true,
      suppressRowHoverHighlight: true
    };
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('cell clicked:', event);
    },
    getRowClass: function getRowClass(params) {
      var _a;

      if (((_a = params.data) === null || _a === void 0 ? void 0 : _a.abrechnungsart) === types_1.BemaGozType.GOZ) {
        return 'privat-row-test';
      } // if (params.node.rowIndex && params.node.rowIndex % 2 === 0) {
      //   return 'row-class-odd';
      // }

    },
    getRowId: function getRowId(params) {
      return params.data.id;
    }
  }
});